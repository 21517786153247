import * as React from "react";
import {createContext, FC, PropsWithChildren, useState} from "react";
import {CartContextProps} from "./interface";
import {ProductoInCart} from "@interfaces/ProductoInCart";

export const CartContext = createContext<CartContextProps>({items: [], setItems: () => {}});

export const CartContextProvider: FC<PropsWithChildren> = ({children}) => {
	const [items, setItems] = useState<ProductoInCart[]>([]);

	return <CartContext.Provider value={{items, setItems}}>{children}</CartContext.Provider>;
};
