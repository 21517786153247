import * as React from "react";

import * as styles from "./styles.module.scss";

export const Footer = () => {
	return (
		<footer className={styles.footer}>
			<span>Copyright©2024 by Un Par Mas. All Right Reserved</span>
			<span>
				Made by{" "}
				<a href="https://www.monknow.dev/es/" target="_blank">
					Juan Diego Rodríguez
				</a>
			</span>
		</footer>
	);
};
