import * as React from "react";
import {useContext} from "react";
import {Link} from "gatsby";

import * as styles from "./styles.module.scss";

import unParMasLogo from "@assets/images/logo.png";
import CartIcon from "@assets/svgs/shooping-cart.inline.svg";

import {CartContext} from "@context/CartContext";

export const NavBar = () => {
	const {items} = useContext(CartContext);

	return (
		<nav className={styles.navBar}>
			<Link to="/">
				<img src={unParMasLogo} alt="Un Par Mas Logo" />
			</Link>
			<Link to="/cart" className={styles.cart}>
				<CartIcon />
				<span>
					{items.reduce((accumulator, {numero}) => {
						return accumulator + numero;
					}, 0)}
				</span>
			</Link>
		</nav>
	);
};
