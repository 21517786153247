import {NavBar} from "@components/NavBar";
import * as React from "react";
import {FC, PropsWithChildren} from "react";

import "@global/style.scss";
import "@assets/fonts/Montserrat/stylesheet.css";

import {Footer} from "@components/Footer";

export const Layout: FC<PropsWithChildren> = ({children}) => {
	return (
		<div>
			<NavBar />
			{children}
			<Footer />
		</div>
	);
};
