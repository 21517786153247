import * as React from "react";
import {GatsbyBrowser} from "gatsby";

import {Layout} from "./src/components/Layout";
import {CartContextProvider} from "./src/context/CartContext";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({element}) => {
	return (
		<CartContextProvider>
			<Layout>{element}</Layout>
		</CartContextProvider>
	);
};
